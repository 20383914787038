/* eslint react/prop-types:0 */
/* eslint no-use-before-define:0 */
/* eslint no-console:0 */
/* eslint react/jsx-one-expression-per-line:0 */
/* eslint no-unused-vars:0 */
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { API_ROOT } from 'service/Configuration';
import { withRouter } from 'react-router';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}{' '}
      <Link color="inherit" href="https://material-ui.com/">
        Smart Trucking
      </Link>{' '}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://eld-crm.s3.amazonaws.com/leftimage.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    // backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LogPanel = () => {
  const onSubmit = e => {
    e.preventDefault();

    const API_URL1 = `${API_ROOT}/api/Login`;
    console.log(LoginDetails);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        username: LoginDetails.email,
        password: LoginDetails.password,
      }),
    };

    Promise.all([fetch(API_URL1, requestOptions)])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data1]) => {
        console.log(`Inserted Shipping Status : ${JSON.stringify(data1)}`);
        localStorage.setItem('crmToken', data1.token);
        localStorage.setItem('u_RoleID', data1.userRoleID);
        localStorage.setItem('full_user_Name', `${data1.firstName} ${data1.lastName}`);
        localStorage.setItem('uID', data1.userID);
        localStorage.setItem('uName', data1.userName);
        localStorage.setItem('teamID', data1.teamID);
      })
      .then(() => {
        if (localStorage.getItem('crmToken') === '1') {
          window.location = `${window.location.origin}/admin/admin-info`;
        } else {
          window.location = `${window.location.origin}/admin/dashboard`;
        }
      });
  };

  const LogAccessData = {
    email: '',
    password: '',
  };

  const [LoginDetails, setLoginDetail] = useState(LogAccessData);

  const LogDataOnChange = event => {
    const { name, value } = event.target;
    setLoginDetail(prevState => ({ ...prevState, [name]: value }));
  };

  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          {/* <form className={classes.form} noValidate> */}
          <form onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="User Name"
              name="email"
              autoComplete="email"
              onChange={LogDataOnChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={LogDataOnChange}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            {/* <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                  </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(LogPanel);
