import * as API from './API';

export const isAdministrator = () => {
  return localStorage.u_RoleID === '1';
};

export const isLoggedIn = () => {
  return (
    localStorage.crmToken !== undefined &&
    localStorage.crmToken !== null &&
    localStorage.crmToken !== ''
  );
};

export const getLeadIdFromQuery = () => {
  const queryParams = window.location.search;
  const RequestParam = new URLSearchParams(queryParams);
  const ReturnLeadID = RequestParam.get('leadid');
  if (ReturnLeadID && ReturnLeadID.trim(' ')) {
    return ReturnLeadID;
  }
  API.errorSwal('Could not retrieve Lead ID from Query String.', null, null, '/admin/Dashboard');
  return 0;
};

export const getDateFromQuery = () => {
  const queryParams = window.location.search;
  const requestParam = new URLSearchParams(queryParams);
  const returnDate = requestParam.get('date');
  if (returnDate && returnDate.trim(' ')) {
    return returnDate;
  }
  API.errorSwal('Could not retrieve Date from Query String.', null, null, '/admin/admin-info');
  return 0;
};
