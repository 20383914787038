/* eslint-disable no-console */
import Swal from 'sweetalert2';
import { API_ROOT } from './Configuration';
import history from '../history';

export const ROOT = API_ROOT;

export const LOGIN = '/api/Login';
export const LOGOUT = '/api/Logout';

export const LEADAUTHENTICATION = '/api/LeadAuthentication';

export const CHARGE_PAYMENTATTEMPTS = '/api/Charge/PaymentAttempts';
export const CHARGE_CARDCHARGE = '/api/Charge/CardCharge';
export const CHARGE_INVOICEONLY = '/api/Charge/InvoiceOnly';
export const CHARGE_CUSTOMERCARDCHARGE = '/api/Charge/CustomerCardCharge';

export const HOSPLATFORM = '/api/HOSPlatform';
export const HOSPLATFORM_GETHOSBYEMAIL = '/api/HOSPlatform/getHosByEmail';

export const INVENTORY_TINSERT_ITEMS_TOTAL = '/api/Inventory/tInsert_Items_total';
export const INVENTORY_SUMMARY_SHIPPING = '/api/Inventory/Summary_shipping';
export const INVENTORY_SUMMARY_DELIVERY_BY_DATE = '/api/Inventory/Summary_delivery_by_Date';

export const INVOICE = '/api/Invoice';
export const INVOICE_NEW = '/api/Invoice/New';
export const INVOICE_INVOICEONLY = '/api/Invoice/InvoiceOnly';
export const INVOICE_INVOICEONLYTOTAL = '/api/Invoice/InvoiceOnlyTotal';

export const LEAD_CLIENT_CC_GETBYCLIENTID = '/api/Lead/Client_CC_GetByClientID';
export const LEAD_CLIENT_CC_LIST_GETBYCLIENTID = '/api/Lead/Client_CC_List_GetByClientID';
export const LEAD_CRM_GETUSERBYUSER2MANAGER = '/api/Lead/CRM_GetUserByUser2Manager';
export const LEAD_CRM_GETMANAGEDUSERSANDSELFBYUSERID =
  '/api/Lead/CRM_GetManagedUsersAndSelfByUserID';
export const LEAD_CRM_GETUSERBYUSERROLEID = '/api/Lead/CRM_GetUserByUserRoleID';
export const LEAD_CRM_GETALLUSER = '/api/Lead/CRM_GetAllUser';
export const LEAD_CRM_LEAD_BILLING_GETBYLEADID = '/api/Lead/CRM_Lead_Billing_GetByLeadID';
export const LEAD_CRM_LEAD_BUSINESS_SAVE_TSR2 = '/api/Lead/CRM_Lead_Business_Save_TSR2';
export const LEAD_CRM_LEAD_BUSINESS_SAVE1 = '/api/Lead/CRM_Lead_Business_Save1';
export const LEAD_CRM_LEAD_COMMENTS_GETBYLEADID = '/api/Lead/CRM_Lead_Comments_GetByLeadID';
export const LEAD_CRM_LEAD_DETAILS_BY_ID = '/api/Lead/CRM_LEAD_DETAILS_BY_ID';
export const LEAD_CRM_LEAD_SAVE3 = '/api/Lead/CRM_Lead_Save3';
export const LEAD_CRM_LEAD_RESELLER2 = '/api/Lead/CRM_Lead_Reseller2';
export const LEAD_DELETE_ORDERITEM = '/api/Lead/Delete_OrderItem';
export const LEAD_DISPATCHDETAILS_BY_LEADID = '/api/Lead/DispatchDetails_By_LeadID';
export const LEAD_SAVE_DISPOSITION_COMMENT = '/api/Lead/Save_Disposition_Comment';
export const LEAD_GET_ALL_DISPOSITION = '/api/Lead/Get_All_Disposition';
export const LEAD_GET_SOME_DISPOSITION = '/api/Lead/Get_Some_Disposition';
export const LEAD_GET_DISPOSITION_BY_USERTOKEN = '/api/Lead/Get_Dispostion_by_UserToken';
export const LEAD_GET_CRM_SUPPORT_REFERRAL = '/api/Lead/Get_CRM_Support_Referal';
export const LEAD_GETCOUNT_SALES_REPORT = '/api/Lead/getCount_Sales_Report';
export const LEAD_GET_INVOICEONLY_ROWS_BY_LEADID = '/api/Lead/Get_InvoiceOnly_Rows_by_LeadID';
export const LEAD_GETDISTINCTSTATE = '/api/Lead/GetDistinctState';
export const LEAD_GETORDERITEM = '/api/Lead/getOrderItem';
export const LEAD_GETWEEKLYSALESDETAILS = '/api/Lead/GetWeeklySalesDetails';
export const LEAD_GETYEARLYSALESDETAILS = '/api/Lead/GetYearlySalesDetails';
export const LEAD_LASTASSOCIATES = '/api/Lead/LastAssociates';
export const LEAD_LIST_TYPE_OF_CARGO = '/api/Lead/List_Type_of_Cargo';
export const LEAD_ORDER_ITEM_INSERT = '/api/Lead/Order_Item_Insert';
export const LEAD_ORDERCOMBINED = '/api/Lead/OrderCombined';
export const LEAD_GETPAYPALPAYMENT_BY_DATE = '/api/Lead/GetPaypalPayment_By_Date';
export const LEAD_PAYPALPAYMENT_GETBYCLIENTIDANDTYPE =
  '/api/Lead/PaypalPayment_GetByClientIDAndType';
export const LEAD_PROSPECTFORM_API_COMBINE = '/api/Lead/ProspectForm_API_Combine';
export const LEAD_RESELLERLIST = '/api/Lead/ResellerList';
export const LEAD_SEARCHLEADS_IDENTIFIER = '/api/Lead/SearchLeads_Identifier';
export const LEAD_SEARCHLEADS_IDENTIFIER_NEW = '/api/Lead/SearchLeads_Identifier_New';
export const LEAD_TAPPLICATION_DETAIL = '/api/Lead/tApplication_detail';
export const LEAD_TPACKAGE_ELD_INSERT = '/api/Lead/tPackage_ELD_Insert';
export const LEAD_TPACKAGE_ELD_UPDATE = '/api/Lead/tPackage_ELD_Update';
export const LEAD_TTRUCK_PRODUCT_SAVE3 = '/api/Lead/tTruck_Product_Save3';
export const LEAD_TTRUCK_PRODUCT_LEADID = '/api/Lead/tTruck_Product_LeadID';
export const LEAD_TTRUCK_PRODUCT_LEADID_NEW = '/api/Lead/tTruck_Product_LeadID_New';
export const LEAD_CRM_FILEDATA_API = '/api/Lead/CRM_File_GetByLeadID';
export const LEAD_TSHIPPING_DELETE = '/api/Lead/tShipping_Delete';
export const LEAD_UPDATE_ORDERITEM = '/api/Lead/Update_OrderItem';

export const LEAD_INSERT_ORDER_ITEM_API = '/api/Lead/Order_Item_Insert';
export const LEAD_ORDER_ITEM_API = '/api/Lead/getOrderItem/';
export const LEAD_UPDATE_ORDER_ITEM_API = '/api/Lead/Update_OrderItem';
export const LEAD_DELETE_ORDER_ITEM_API = '/api/Lead/Delete_OrderItem';

export const LEAD_CLIENT_CC_BY_CLIENTID_API = '/api/Lead/Client_CC_GetByClientID';

export const LEAD_CALLBACK_BY_LEADID = '/api/Lead/CRM_Lead_CallBackGetByLeadID';
export const LEAD_SAVE_CALLBACK_DATA = '/api/Lead/CRM_Lead_CallBack_Save';
export const LEAD_SAVE_COMMENT = '/api/Lead/CRM_Lead_Comments_Save';
export const LEAD_GET_COMMENT_BY_LEADID = '/api/Lead/CRM_Lead_Comments_GetByLeadID';
export const LEAD_SEND_EMAIL_TO_USER = '/api/SendEmailToUser/SendEmail_To_User';

export const LEAD_DRIVER_LIST_BY_LEADID = '/api/Lead/tDriver_LeadID';
export const LEAD_INSERT_DRIVER_DATA_LEADID = '/api/Lead/tDriver_Insert2';
export const LEAD_UPDATE_DRIVER_DATA_LEADID = '/api/Lead/tDriver_Update';
export const LEAD_DELETE_DRIVER_DATA_LEADID = '/api/Lead/tDriver_Delete';

export const LEAD_VEHICLE_LIST_BY_LEADID = '/api/Lead/CRM_Lead_tVehicle';
export const LEAD_INSERT_VEHICLE_DATA_LEADID = '/api/Lead/tVehicle_Insert';
export const LEAD_UPDATE_VEHICLE_DATA_LEADID = '/api/Lead/tVehicle_Update';
export const LEAD_DELETE_VEHICLE_DATA_LEADID = '/api/Lead/tVehicle_Delete';

export const LEAD_SHIPPING_DATA_LIST_BY_LEADID = '/api/Lead/CRM_Lead_tShipping';
export const LEAD_INSERT_SHIPPING_DATA_LEADID = '/api/Lead/tShipping_Insert';
export const LEAD_UPDATE_SHIPPING_DATA_LEADID = '/api/Lead/tShipping_Update';
export const LEAD_DELETE_SHIPPING_DATA_LEADID = '/api/Lead/tShipping_Delete';

export const LEAD_ELD_PACKAGE_DATA_LIST_BY_LEADID = '/api/Lead/tPackage_ELD';
export const LEAD_INSERT_ELD_PACKAGE_DATA_LEADID = '/api/Lead/tPackage_ELD_Insert';
export const LEAD_UPDATE_ELD_PACKAGE_DATA_LEADID = '/api/Lead/tPackage_ELD_Update';
export const LEAD_DELETE_ELD_PACKAGE_DATA_LEADID = '/api/Lead/Delete_ELD_Package';

export const LEAD_TRUCK_MODEL_LEAD_MAKEID = '/api/Lead/Trucks_model_list';
export const LEAD_CRM_FILEUPLOAD_SERVER = '/api/Lead/CRM_FileUpload_Server';
export const LEAD_CRM_FILE_SAVE = '/api/Lead/CRM_File_Save';

export const DASHBOARD_GET_YEARLY_SALESDETAILS = '/api/Lead/GetYearlySalesDetails';
export const DASHBOARD_GET_WEEKLY_SALESDETAILS = '/api/Lead/GetWeeklySalesDetails';
export const DASHBOARD_GET_COUNT_SALES_REPORT = '/api/Lead/getCount_Sales_Report';

export const LEAD_SAVE_SHIPPING_BUSSINESS_DETAILS = '/api/Lead/CRM_Lead_Business_Shipping_Save';
export const LEAD_SAVE_RESELLER_BUSSINESS_DETAILS = '/api/Lead/CRM_Lead_Reseller2';
export const LEAD_SAVE_BILLING_BUSSINESS_DETAILS = '/api/Lead/CRM_Lead_Billing_Save';

export const LEAD_CLIENT_EMAIL_BY_LEADID = '/api/Lead/CRM_Lead_Business_GetByLeadID';
export const LEAD_INTERNAL_EMAIL_BY_TEAMID = '/api/Lead/CRM_GetEmailUserByUserRoleID';
export const LEAD_SEND_EMAIL_TO_CLIENT_USER_RECEPIENT =
  '/api/SendEmailToUser/SendEmail_To_Client_User';

export const LEAD_LEAD_ASSOCIATION_CHECKLEADFORACCESS =
  '/api/Lead/Lead_Association_CheckLeadForAccess';
export const LEAD_LEAD_ASSOCIATION_SAVE = '/api/Lead/Lead_Association_Save';
export const LEAD_LEAD_ASSOCIATION_DELETE = '/api/Lead/Lead_Association_Delete';
export const LEAD_LEAD_ASSOCIATION_GETBYLEADID = '/api/Lead/Lead_Association_GetByLeadID';

export const TWILIOPLATFORM = '/api/TwilioPlatform';
export const TWILIOPLATFORM_CONFERENCEPARTICIPANTS = '/api/TwilioPlatform/ConferenceParticipants';
export const TWILIOPLATFORM_CONFERENCERECORDS = '/api/TwilioPlatform/ConferenceRecords';
export const TWILIOPLATFORM_NUMBERASSIGNMENTS = '/api/TwilioPlatform/NumberAssignments';
export const TWILIOPLATFORM_RECORDS = '/api/TwilioPlatform/Records';
export const TWILIOPLATFORM_CUSTOMNUMBER = '/api/TwilioPlatform/CustomNumber';
export const TWILIOPLATFORM_REMOVEPARTICIPANT = '/api/TwilioPlatform/RemoveParticipant';
export const TWILIOPLATFORM_TRANSFER = '/api/TwilioPlatform/Transfer';
export const SMS_OUTGOING = '/api/Sms/Outgoing';
export const SMS_RECORDS = '/api/Sms/Records';
export const SMS_READ = '/api/Sms/Read';
export const SMS_ALLMANAGEDRECORDS = '/api/Sms/AllManagedRecords';

export const NOTIFICATION_CHECK = '/api/Notification/Check';
export const PDFGENERATE_ELDMANDATEFLYERPDF = '/api/PdfGenerate/ELDMandateFlyerPDF';
export const PDFGENERATE_ELDMANDATEEMAILSENDPDF = '/api/PdfGenerate/ELDMandateEmailSendPDF';

export const HOSPLATFORM_HOS_ASSET_DETAILS = '/api/HOSPlatform/Hos_Asset_Details';
export const HOSPLATFORM_HOS_DRIVER_DETAILS = '/api/HOSPlatform/Hos_Driver_Details';

export const PROCESSOR_CHECK_PHONENUMBER = '/api/Processor/Check_Phonenumber';
export const LEAD_CRM_ADD_NEW_LEAD = '/api/Lead/CRM_Add_New_Lead';
export const PROCESSOR_CRM_LEAD_BUSINESS_SAVE_ADD = '/api/Processor/CRM_Lead_Business_Save_add';

export const LEAD_CRM_LEAD_TO_CUSTOMER_MANAGER_SAVE = '/api/Lead/CRM_Lead_To_Customer_Manager_Save';
export const LEAD_CRM_LEAD_TO_CUSTOMER_MANAGER_BY_LEADID =
  '/api/Lead/CRM_Lead_To_Customer_Manager_By_LeadID';
export const LEAD_CRM_PACKAGES_BY_NAME = '/api/Lead/CRM_Packages_By_Name';
export const LEAD_GET_PAYPALPAYMENT_BY_RESELLER = '/api/Lead/Get_PaypalPayment_By_Reseller';
export const LEAD_GET_RESELLER_LIST = '/api/Lead/Get_Reseller_List';

export const LEAD_CRM_VOICECALLDETAILS = '/api/Lead/CRM_VoiceCallDetails';
export const LEAD_CRM_SMSRECORDDETAILS = '/api/Lead/CRM_SMSRecordDetails';

export const LEAD_CRM_ELDPACKAGES_BY_LEADID = '/api/Lead/CRM_ELDPackages_By_LeadID';
export const PDFGENERATE_SALEAGREEMENTPDFSENDTOEMAIL =
  '/api/PdfGenerate/SaleAgreementPDFSendToEMail';

export const logOut = () => {
  // e.preventDefault();
  const UserID = localStorage.getItem('uID');
  const body = {
    LogoutRequest: {
      UserID: 0,
    },
  };
  const API_URL1 = `${ROOT}${LOGOUT}`;
  console.log(body);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  };

  Promise.all([fetch(API_URL1, requestOptions)]).then(([res1]) => {
    // eslint-disable-next-line no-console
    console.log(
      `Logging out thru API for User ID: ${UserID} ${
        res1.status === 200 ? 'successful' : 'failed'
      }.`,
    );
  });
  localStorage.clear();
  window.location = '/';
};

export async function successSwal(title = null) {
  let timerInterval;
  Swal.fire({
    title: `${title || 'Success!'}`,
    icon: 'success',
    // html: 'I will close in <b></b> milliseconds.',
    timer: 3000,
    timerProgressBar: true,
    onBeforeOpen: () => {
      Swal.showLoading();
      timerInterval = setInterval(() => {
        const content = Swal.getContent();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            b.textContent = Swal.getTimerLeft();
          }
        }
      }, 100);
    },
    onClose: () => {
      clearInterval(timerInterval);
    },
  }).then(result => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('I was closed by the timer');
    }
  });
}

export async function errorSwal(
  errorMessage,
  APIurl = null,
  statusCode = null,
  redirectUrl = null,
) {
  const notification = await Swal.fire({
    titleText: `Error${statusCode ? ` ${statusCode}` : ''}`,
    html: `<b>Error Text:</b> ${
      typeof errorMessage === 'object' ? JSON.stringify(errorMessage) : errorMessage
    }${
      APIurl ? `<br />Error at URL: ${APIurl}<br />` : ''
    }<br />Hit Enter, Space, Esc, or click anywhere on the background to ${
      redirectUrl ? `redirect to ${redirectUrl}` : `reload`
    }.`,
    icon: 'error',
    showConfirmButton: false,
    showCancelButton: true,
  });
  if (notification.dismiss !== Swal.DismissReason.cancel) {
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      window.location.reload(true);
    }
  } else {
    Swal.fire({
      html: 'Data will not load until you refresh.<br />Please save your work and refresh later.',
    });
  }
}

// export async function logOutSwal(title = null) {
//   let timerInterval;
//   Swal.fire({
//     title: `${title || 'Credentials Invalid.'}`,
//     icon: 'warning',
//     html: 'Logging out in <b></b> milliseconds.',
//     timer: 3000,
//     timerProgressBar: true,
//     onBeforeOpen: () => {
//       Swal.showLoading();
//       timerInterval = setInterval(() => {
//         const content = Swal.getContent();
//         if (content) {
//           const b = content.querySelector('b');
//           if (b) {
//             b.textContent = Swal.getTimerLeft();
//           }
//         }
//       }, 100);
//     },
//     onClose: () => {
//       clearInterval(timerInterval);
//     },
//   }).then(result => {
//     /* Read more about handling dismissals below */
//     if (result.dismiss === Swal.DismissReason.timer) {
//       console.log('I was closed by the timer');
//     }
//     // localStorage.clear();
//     // window.location = '/';
//     logOut();
//   });
// }

export const noRetry = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: localStorage.crmToken,
  },
  interceptors: {
    response: async response => {
      if (response.status === 401) {
        logOut();
      } else if (response.status === 500) {
        errorSwal(response.text, response.url, response.status);
        console.log(
          `Error hitting API at: ${response.url}\n Error ${response.status}: ${response.statusText}`,
        );
      }
      return response;
    },
  },
  retries: 0,
  cachePolicy: 'no-cache',
  suspense: true,
};

export const fileUploadNoRetry = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    Authorization: localStorage.crmToken,
  },
  interceptors: {
    response: async response => {
      if (response.status === 401) {
        logOut();
      } else if (response.status === 500) {
        errorSwal(response.text, response.url, response.status);
        console.log(
          `Error hitting API at: ${response.url}\n Error ${response.status}: ${response.statusText}`,
        );
      }
      return response;
    },
  },
  retries: 0,
  cachePolicy: 'no-cache',
  suspense: true,
};

export const retryOptions = {
  ...noRetry,
  retries: 3,
  timeout: 10000,
  retryOn: async ({ response }) => {
    return response && response.status >= 300 && response.status !== 401;
  },
};

export const retryAndCache = {
  ...noRetry,
  cachePolicy: 'cache-first',
  retries: 3,
  timeout: 10000,
};
