/* eslint import/prefer-default-export:0 */
/* eslint prefer-const:0 */
let backendHost;

// backendHost = 'https://pcfm2zq6xl.execute-api.us-east-2.amazonaws.com/Prod';

backendHost = process.env.REACT_APP_BASE_URL;

// backendHost = 'http://localhost:50145';

export const API_ROOT = `${backendHost}`;
