/* eslint max-len:0 */
/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import cartNumber from 'reducer/cartNumber';
// import { UserService } from 'service/userService';

// if (localStorage.getItem('crmToken') === null) {
//   UserService();
// }

// store.subscribe(() => {
//   console.log(store.getState().lastAction);
// });

ReactDOM.render(<App />, document.getElementById('root'));
